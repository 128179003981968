@font-face {
    font-family: DM;
    src: url("./fonts/DMSerifDisplay-Regular.ttf"); }
  
  @font-face {
    font-family: DM-italic;
    src: url("./fonts/DMSerifDisplay-Italic.ttf"); }
  
  @font-face {
    font-family: Nanum;
    src: url("./fonts/DMSerifDisplay-Regular.ttf"); }
  
  html {
    scroll-behavior: smooth; }
  
  body {
    background-color: #212529;
    transition-duration: 0.2s;
    position: relative;
    overflow-x: hidden !important; }
  
  h1, h2, h3, h4, h5, h6 {
    font-family: DM; }
  
  .scrolled {
    background-color: red !important; }
  
  .navbar-brand {
    height: 48px;
    width: auto; }
  
  .wrapper {
    height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
    perspective: 10px;
    transition-duration: 0.2s; }
  
  .background {
    position: absolute;
    height: 100%;
    width: 100%;
    object-fit: cover;
    z-index: -1;
    transform: translateZ(-10px) scale(3) translateY(-1vh);
    filter: saturate(20%); }
  
  .landing-container {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    height: 95vh;
    transform-style: preserve-3d;
    z-index: -1; }
  
  button {
    font-family: Nanum !important; }
  
  .btn-primary {
    background: #569342;
    color: white;
    border: 1px solid white;
    transition-duration: 0.2s; }
    .btn-primary:hover {
      background-color: white;
      color: #000000 !important;
      transition-duration: 0.2s;
      border: 1px solid white !important; }
    .btn-primary:active {
      background-color: #1e5413 !important;
      border: 1px solid white !important;
      color: white !important;
      transform: translateY(1px) !important; }
  
  .btn-secondary {
    background-color: rgba(255, 255, 255, 0);
    border: 1px solid white;
    transition-duration: 0.2s; }
    .btn-secondary:hover {
      background-color: white;
      color: #000000 !important;
      transition-duration: 0.2s; }
    .btn-secondary:active {
      background-color: #1e5413 !important;
      border: 1px solid white !important;
      color: white !important;
      transform: translateY(1px) !important; }
  
  .btn-faq {
    background-color: #000000;
    color: white;
    border: 1px solid white;
    transition-duration: 0.2s;
    font-size: x-large;
    padding-left: 30px;
    padding-right: 30px; }
    .btn-faq:hover {
      background-color: white;
      color: black;
      border: 1px solid black;
      transition-duration: 0.2s; }
    .btn-faq:active {
      transform: translateY(1px);
      background-color: #1e5413;
      color: white;
      border: 1px solid white;
      transition-duration: 0.2s; }
  
  .btn-secondary:hover {
    background-color: white;
    color: #212121;
    border: 1px solid white;
    transition-duration: 0.2s; }
  
  .social-link {
    transform: scale(1.2); }
    .social-link i {
      transition-duration: 0.2s;
      color: white; }
      .social-link i:hover {
        transform: translateY(-2px);
        cursor: pointer !important; }
  
  .navbar {
    transition-delay: 0.6s !important;
    transition-duration: 0.5s !important; }
  
  .meet-the-artists-img {
    max-height: 60vh;
    filter: saturate(20%); }
  
  .lead-text {
    font-size: x-large; }
  
  .a-link {
    color: #569342;
    text-decoration: none; }
    .a-link:hover {
      transition-duration: 0.2s;
      transform: translateY(-1px);
      cursor: pointer; }
  
  .contact-container {
    background-image: url("./imgs/banner.webp");
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 80vh; }
  
  .img-frame {
    background-color: white;
    border: 3px solid #ffffff; }
  
  .meet-the-artists-img {
    box-shadow: 2px 2px 6px #000000; }
  
  .contact-outer-frame {
    border: 3px solid rgba(255, 255, 255, 0);
    background-color: #ffffff;
    padding: 0px;
    border-radius: 10px; }
  
  .contact-inner-frame {
    box-shadow: 3px 3px 9px #3a3a3a; }
  
  .background-location-img {
    position: absolute;
    width: 100vw;
    min-height: 60vh;
    max-height: 60vh;
    z-index: -1;
    object-fit: cover;
    filter: saturate(20%);
    overflow: hidden !important; }
  
  .inner-location-container {
    position: relative;
    margin: auto;
    min-height: 60vh; }
  
  .location-container {
    min-height: 60vh;
    z-index: 10;
    overflow-y: hidden; }
  
  .hours-container {
    background-color: #212529 !important;
    padding: 40px;
    font-size: larger;
    background-color: #212529;
    color: white;
    box-shadow: 3px 3px 3px #434343; }
    .hours-container .closed {
      color: red; }
  
  .hours-container-outer {
    background-color: white !important;
    padding: 8px;
    border: 2px solid #666666; }
  
  .footer-icon {
    color: #212529;
    background-color: rgb(210, 210, 210);
    padding: 18px;
    border: 1px solid white;
    border-radius: 50px; 
    transform: scale(0.8);}
  
  .fa-envelope {
    padding: 16px;
    transform: scale(0.8);
  }
  
.navigation-item-header-container {
    height: 25vh
}
.story-img {
width: 100%;
box-shadow: 3px 3px 6px black;
height: 100%
}
.story-img-frame {
background-color: #ffffff;
border: 3px solid black
}
.faq-container p {
    color: rgb(188, 188, 188)
}
.faq-container h4 {
    color: rgb(230, 230, 230)
}
.faq-link {
 transition-duration: 0.2s;
}
.faq-link:hover {
    transition-duration: 0.2s;
    background-color: #32383d;
   }
.faq-selection {
    background-color: #1c1e22;
}
.gallery-img-container {
  opacity: 0;
  animation-name: fade-in;
  animation-duration: 1s;
  animation-fill-mode: forwards;

}
.gallery-img {
  height: 100%;
  width: auto;
  object-fit: cover !important;
  padding-bottom: 5px;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 5px;
  background-color: white;
  transform: scale(1.0);
}
@media only screen and (max-width: 767px) {
  .gallery-img {
    height: 50vh;
    transform: scale(0.9);
  }
}
.artist-selection {
  height: 30px;
  width: 50%;
}
.aftercare-link {
  border: 1px solid white !important;
  padding: 8px;
  border-radius: 8px;
  transition-duration: 0.2s
}
.aftercare-link:hover {
  border: 1px solid white !important;
  padding: 8px;
  color: black;
  background-color: white;
  border-radius: 8px;
  transition-duration: 0.2s;
  transform: translateY(-2px)
}
.footer-creator-text {
  font-size: smaller;
  color: rgb(85, 63, 63)
} 
.footer-creator-text a{
} 
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.modal-header-text {
  color: #000000 !important;
  font-size: x-large !important;
}
.privacy-inner h5 { 
  color: white
}
.privacy-inner p {
  color: rgb(214, 214, 214)
}